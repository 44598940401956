import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'physician', loadChildren: './physician/physician.module#PhysicianPageModule' },
  { path: 'practice', loadChildren: './practice/practice.module#PracticePageModule' },
  { path: 'speciality', loadChildren: './speciality/speciality.module#SpecialityPageModule' },
  { path: 'hospital', loadChildren: './hospital/hospital.module#HospitalPageModule' },
  { path: 'nursing', loadChildren: './nursing/nursing.module#NursingPageModule' },
  { path: 'hospital-details/:hospitalId', loadChildren: './hopital-details/hopital-details.module#HopitalDetailsPageModule' },
  { path: 'physician/:hospitalId', loadChildren: './physician/physician.module#PhysicianPageModule' },
  { path: 'practice/:hospitalId', loadChildren: './practice/practice.module#PracticePageModule' },
  { path: 'speciality/:hospitalId', loadChildren: './speciality/speciality.module#SpecialityPageModule' },
  { path: 'hospital-details', loadChildren: './hopital-details/hopital-details.module#HopitalDetailsPageModule' },
  { path: 'speciality-view/:specId', loadChildren: './speciality-view/speciality-view.module#SpecialityViewPageModule' },
  { path: 'speciality-view/:specId/:hospitalId', loadChildren: './speciality-view/speciality-view.module#SpecialityViewPageModule' },
  { path: 'nursing-view/:hospitalId', loadChildren: './nursing-view/nursing-view.module#NursingViewPageModule' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
